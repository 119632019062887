import React, { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { ref, get } from 'firebase/database';
import { firestore, database, auth } from '../firebase';
import 'rc-slider/assets/index.css';
import Slider from 'rc-slider';
import {
  ScatterChart, Scatter, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';
import { timeFormat } from 'd3-time-format';
import { modelsByMake } from '../data/modelsByMake';
import Loading from './../components/Loading'; // Assuming you have a Loading component

const MarketAndValuationAnalysis = () => {
  const [listings, setListings] = useState([]);
  const [valuations, setValuations] = useState([]);
  const [filter, setFilter] = useState({ make: '', model: '', site: '', yearRange: [2000, 2024] });
  const [currentYearRange, setCurrentYearRange] = useState([2000, 2024]);
  const [userLocation, setUserLocation] = useState('');
  const [showHighEstimate, setShowHighEstimate] = useState(true);
  const [showMedEstimate, setShowMedEstimate] = useState(true);
  const [showLowEstimate, setShowLowEstimate] = useState(true);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUserLocation = async () => {
      try {
        const userId = auth.currentUser.uid;
        const locationRef = ref(database, `/users/${userId}/settings/user_region`);
        const locationSnapshot = await get(locationRef);
        if (locationSnapshot.exists()) {
          setUserLocation(locationSnapshot.val().charAt(0).toUpperCase() + locationSnapshot.val().slice(1));
        }
      } catch (error) {
        console.error('Error fetching user location:', error);
      }
    };

    const fetchListings = async () => {
      try {
        const listingsCollection = collection(firestore, 'listings');
        const listingSnapshot = await getDocs(listingsCollection);
        const listingList = listingSnapshot.docs.map(doc => {
          const data = doc.data();
          return {
            id: doc.id,
            ...data,
            timestamp: data.timestamp ? new Date(data.timestamp * 1000).getTime() : null,
          };
        });
        setListings(listingList);
      } catch (error) {
        console.error('Error fetching listings:', error);
      }
    };

    const fetchValuations = async () => {
      try {
        const valuationsCollection = collection(firestore, 'valuations');
        const valuationSnapshot = await getDocs(valuationsCollection);
        const valuationList = valuationSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          highEstimate: Number(doc.data().highEstimate),
          lowEstimate: Number(doc.data().lowEstimate),
          medEstimate: Number(doc.data().medEstimate),
          odometer: Number(doc.data().odometer)
        }));
        setValuations(valuationList);
      } catch (error) {
        console.error('Error fetching valuations:', error);
      }
    };

    const fetchData = async () => {
      await fetchUserLocation(); // Fetch user location first
      await Promise.all([fetchListings(), fetchValuations()]);
      setLoading(false); // Set loading to false after all data is fetched
    };

    fetchData();
  }, []);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilter(prev => ({ ...prev, [name]: value }));
  };

  const filteredListings = listings.filter(listing =>
    (filter.make === '' || listing.listing.make.toLowerCase().includes(filter.make.toLowerCase())) &&
    (filter.model === '' || listing.listing.model.toLowerCase().includes(filter.model.toLowerCase())) &&
    (listing.listing.year === null || listing.listing.year >= filter.yearRange[0] && listing.listing.year <= filter.yearRange[1]) &&
    (listing.location === userLocation) &&
    (filter.site === '' || listing.site.toLowerCase().replace(/\s+/g, '_') === filter.site.toLowerCase().replace(/\s+/g, '_'))
  );

  const filteredValuations = valuations.filter(valuation =>
    (filter.make === '' || valuation.make.toLowerCase().includes(filter.make.toLowerCase())) &&
    (filter.model === '' || valuation.model.toLowerCase().includes(filter.model.toLowerCase())) &&
    (valuation.year >= filter.yearRange[0] && valuation.year <= filter.yearRange[1])
  );

  const makeOptions = Object.keys(modelsByMake).map(make => <option key={make} value={make}>{make}</option>);
  const modelOptions = filter.make ? modelsByMake[filter.make].map(model => <option key={model} value={model}>{model}</option>) : null;

  const renderTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <div className="bg-white p-2 border border-gray-400 rounded">
          {data.make && <p><strong>Make:</strong> {data.make}</p>}
          {data.model && <p><strong>Model:</strong> {data.model}</p>}
          {data.location && <p><strong>Location:</strong> {data.location}</p>}
          {data.current_price && <p><strong>Current Price:</strong> ${data.current_price}</p>}
          {data.engine_size && <p><strong>Engine Size:</strong> {data.engine_size} cc</p>}
          {data.year && <p><strong>Year:</strong> {data.year}</p>}
          {data.highEstimate && <p><strong>High Estimate:</strong> ${data.highEstimate}</p>}
          {data.medEstimate && <p><strong>Medium Estimate:</strong> ${data.medEstimate}</p>}
          {data.lowEstimate && <p><strong>Low Estimate:</strong> ${data.lowEstimate}</p>}
          {data.odometer && <p><strong>Odometer:</strong> {data.odometer} km</p>}
        </div>
      );
    }

    return null;
  };

  const prepareData = (data, estimateType) => data.map(valuation => ({
    odometer: valuation.odometer,
    estimate: valuation[estimateType],
    make: valuation.make,
    model: valuation.model,
    year: valuation.year,
    highEstimate: valuation.highEstimate,
    medEstimate: valuation.medEstimate,
    lowEstimate: valuation.lowEstimate,
    buy_now_price: valuation.buy_now_price
  }));

  const highEstimateData = prepareData(filteredValuations, 'highEstimate');
  const medEstimateData = prepareData(filteredValuations, 'medEstimate');
  const lowEstimateData = prepareData(filteredValuations, 'lowEstimate');

  const listingsData = filteredListings.map(listing => {
    return {
      timestamp: listing.timestamp,
      location: listing.location,
      current_price: listing.listing.current_price,
      make: listing.listing.make,
      model: listing.listing.model,
      engine_size: listing.listing.engine_size,
      site: listing.site,
      year: listing.listing.year,
      odometer: listing.odometer
    };
  });

  const formatTime = timeFormat('%b %d, %Y %H:%M');

  return (
    <div className="container mx-auto p-4 mt-12">
      {loading ? (
        <Loading />
      ) : (
        <>
          <div className="mb-4">
            <select name="make" value={filter.make} onChange={handleFilterChange} className="block w-full mb-2 p-2 border rounded">
              <option value="">Select Make</option>
              {makeOptions}
            </select>
            <select name="model" value={filter.model} onChange={handleFilterChange} className="block w-full mb-2 p-2 border rounded" disabled={!filter.make}>
              <option value="">Select Model</option>
              {modelOptions}
            </select>
            <select name="site" value={filter.site} onChange={handleFilterChange} className="block w-full mb-2 p-2 border rounded">
              <option value="">Filter by Site</option>
              <option value="facebook">Facebook</option>
              <option value="trade_me">Trade Me</option>
            </select>
            <div className="mx-2">
              <label className="block pv-1">{currentYearRange[0]} - {currentYearRange[1]}</label>
              <Slider
                range
                min={2000}
                max={2024}
                defaultValue={filter.yearRange}
                step={1}
                onChange={(value) => setCurrentYearRange(value)}
                onAfterChange={(value) => {
                  setFilter(prev => ({ ...prev, yearRange: value }));
                }}
              />
            </div>
          </div>
          <div className="bg-white shadow-lg rounded-lg p-4 mb-4">
            <h2 className="text-xl mb-4">Market Analysis</h2>
            <ResponsiveContainer width="100%" height={400}>
              <ScatterChart>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="timestamp"
                  name="Timestamp"
                  type="number"
                  domain={['auto', 'auto']}
                  tickFormatter={(unixTime) => formatTime(new Date(unixTime))}
                  scale="time"
                />
                <YAxis dataKey="current_price" name="Current Price" unit="$" />
                <Tooltip content={renderTooltip} cursor={{ strokeDasharray: '3 3' }} />
                <Legend />
                <Scatter name="Listings" data={listingsData} fill="#8884d8" />
              </ScatterChart>
            </ResponsiveContainer>
          </div>
          <div className="bg-white shadow-lg rounded-lg p-4">
            <h2 className="text-xl mb-4">Valuation Analysis</h2>
            <ResponsiveContainer width="100%" height={400}>
              <ScatterChart>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="odometer" name="Odometer" unit=" km" type="number" domain={[0, 'auto']} />
                <YAxis dataKey="estimate" name="Estimate" unit="$" />
                <Tooltip content={renderTooltip} cursor={{ strokeDasharray: '3 3' }} />
                <Legend />
                {showHighEstimate && <Scatter name="High Estimate" data={highEstimateData} fill="#82ca9d" />}
                {showMedEstimate && <Scatter name="Medium Estimate" data={medEstimateData} fill="#8884d8" />}
                {showLowEstimate && <Scatter name="Low Estimate" data={lowEstimateData} fill="#ff7300" />}
              </ScatterChart>
            </ResponsiveContainer>
          </div>
        </>
      )}
    </div>
  );
};

export default MarketAndValuationAnalysis;
