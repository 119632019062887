// firebase.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { getFirestore } from "firebase/firestore";
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

const firebaseConfig = {
    apiKey: "AIzaSyAjs8na94aKspDO0mVk426iH5zapvqdKtg",
    authDomain: "swiftbrobot.firebaseapp.com",
    databaseURL: "https://swiftbrobot-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "swiftbrobot",
    storageBucket: "swiftbrobot.appspot.com",
    messagingSenderId: "1044521733573",
    appId: "1:1044521733573:web:60e5f4322265e5a3088fac",
    measurementId: "G-N95SJCDRJD"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const database = getDatabase(app);
const firestore = getFirestore(app);
const messaging = getMessaging(app);

export { auth, database, firestore, messaging, getToken, onMessage };
