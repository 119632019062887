import React, { useState, useEffect } from 'react';
import { getDatabase, ref, set, get } from 'firebase/database';
import { getAuth } from 'firebase/auth';
import Loading from '../components/Loading';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

const manufacturersList = [
  'Toyota', 'Ford', 'Honda', 'BMW', 'Mercedes-Benz', 'Audi', 'Volkswagen',
  'Chevrolet', 'Nissan', 'Hyundai', 'Kia', 'Subaru', 'Mazda', 'Lexus',
  'Porsche', 'Jaguar', 'Ferrari', 'Lamborghini', 'Volvo', 'Mitsubishi',
  'Land Rover', 'Tesla', 'Buick', 'GMC', 'Chrysler', 'Dodge', 'Jeep',
];

const auctionTypes = [
  'Turners Auctions', 'New Standard Auction Listings', 'End of Life',
  'Tender Listings', 'Online Auction Listings', 'Trade Me Auctions',
  'New Listings (no reserve/reserve met)', 'New Listings where the reserve is met',
];

// Function to sanitize keys
const sanitizeKey = (key) => key.replace(/[.#$/[\]]/g, '_');

// Function to unsanitize keys (for display purposes)
const unsanitizeKey = (key) => key.replace(/_/g, ' ');

const VehicleFilter = () => {
  const [manufacturers, setManufacturers] = useState(
    manufacturersList.reduce((acc, curr) => ({ ...acc, [curr]: false }), {})
  );
  const [auctionSettings, setAuctionSettings] = useState(
    auctionTypes.reduce((acc, curr) => ({ ...acc, [curr]: false }), {})
  );
  const [desiredPrice, setDesiredPrice] = useState('');
  const [odometerRange, setOdometerRange] = useState([0, 300000]);
  const [priceRange, setPriceRange] = useState([1000, 20000]);
  const [yearRange, setYearRange] = useState([2000, 2024]);
  const [loading, setLoading] = useState(true);
  const [initialLoad, setInitialLoad] = useState(true);
  const [notification, setNotification] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      const db = getDatabase();
      const user = getAuth().currentUser;

      if (user) {
        const userRef = ref(db, `users/${user.uid}/filter/`);
        try {
          const snapshot = await get(userRef);
          const data = snapshot.val();

          if (data) {
            setManufacturers(prev => ({ ...prev, ...data.manufacturers }));
            const unsanitizedAuctionSettings = {};
            Object.keys(data.auctionSettings || {}).forEach((key) => {
              unsanitizedAuctionSettings[unsanitizeKey(key)] = data.auctionSettings[key];
            });
            setAuctionSettings(prev => ({ ...prev, ...unsanitizedAuctionSettings }));
            setDesiredPrice(data.desiredPrice || '');
            if (data.odometerRange) setOdometerRange(data.odometerRange);
            if (data.priceRange) setPriceRange(data.priceRange);
            if (data.yearRange) setYearRange(data.yearRange);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }
      setLoading(false);
      setInitialLoad(false);
    };

    fetchData();
  }, []);

  const saveData = async () => {
    const db = getDatabase();
    const user = getAuth().currentUser;

    if (user) {
      const userRef = ref(db, `users/${user.uid}/filter/`);

      const filteredManufacturers = Object.entries(manufacturers)
        .filter(([, value]) => value)
        .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

      const sanitizedAuctionSettings = {};
      Object.keys(auctionSettings).forEach((key) => {
        sanitizedAuctionSettings[sanitizeKey(key)] = auctionSettings[key];
      });

      try {
        await set(userRef, {
          manufacturers: filteredManufacturers,
          auctionSettings: sanitizedAuctionSettings,
          desiredPrice: parseInt(desiredPrice, 10) || '',
          odometerRange,
          priceRange,
          yearRange,
        });
        // console.log('Data saved successfully');
      } catch (error) {
        console.error('Error saving filter settings:', error);
      }
    } else {
      console.log('No authenticated user found.');
    }
  };

  useEffect(() => {
    if (!initialLoad) {
      saveData();
    }
  }, [manufacturers, auctionSettings, desiredPrice, odometerRange, priceRange, yearRange]);

  const handleCheckboxChange = (event) => {
    setManufacturers(prev => ({
      ...prev,
      [event.target.name]: event.target.checked,
    }));
  };

  const handleToggleChange = (type) => {
    setAuctionSettings(prev => ({
      ...prev,
      [type]: !prev[type],
    }));
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="mt-12 mb-12">
      <div className="container mx-auto p-6 bg-white shadow-xl rounded-lg max-w-4xl">
        <h1 className="text-3xl font-extrabold mb-8 text-center text-gray-800">
          Vehicle Filter Settings
        </h1>
        {notification && (
          <div className="bg-green-500 text-white text-center py-2 rounded mb-6">
            {notification}
          </div>
        )}
        <div className="mb-10">
          <h2 className="text-2xl font-semibold mb-6 text-gray-700">
            Select Manufacturers
          </h2>
          <div className="grid grid-cols-2 sm:grid-cols-3 gap-4">
            {manufacturersList.map((manufacturer) => (
              <label
                key={manufacturer}
                className="flex items-center space-x-3 bg-gray-100 p-3 rounded-lg shadow-sm hover:bg-gray-200 transition duration-200"
              >
                <input
                  type="checkbox"
                  name={manufacturer}
                  checked={manufacturers[manufacturer]}
                  onChange={handleCheckboxChange}
                  className="form-checkbox h-6 w-6 text-indigo-600"
                  style={{ accentColor: '#146C94' }}
                />
                <span className="text-lg font-medium text-gray-800">
                  {manufacturer}
                </span>
              </label>
            ))}
          </div>
        </div>

        <div className="mb-10">
          <label className="block text-2xl font-semibold mb-4 text-gray-700">
            Odometer Range (km)
          </label>
          <Slider
            range
            min={0}
            max={500000}
            step={10000}
            value={odometerRange}
            onChange={setOdometerRange}
            trackStyle={{ backgroundColor: '#146C94' }}
            handleStyle={{ borderColor: '#146C94' }}
            railStyle={{ backgroundColor: '#ddd' }}
          />
          <div className="flex justify-between mt-4 text-lg text-gray-600">
            <span>{odometerRange[0].toLocaleString()} km</span>
            <span>{odometerRange[1].toLocaleString()} km</span>
          </div>
        </div>

        <div className="mb-10">
          <label className="block text-2xl font-semibold mb-4 text-gray-700">
            Buying Range ($)
          </label>
          <Slider
            range
            min={0}
            max={200000}
            step={1000}
            value={priceRange}
            onChange={setPriceRange}
            trackStyle={{ backgroundColor: '#146C94' }}
            handleStyle={{ borderColor: '#146C94' }}
            railStyle={{ backgroundColor: '#ddd' }}
          />
          <div className="flex justify-between mt-4 text-lg text-gray-600">
            <span>${priceRange[0].toLocaleString()}</span>
            <span>${priceRange[1].toLocaleString()}</span>
          </div>
        </div>

        <div className="mb-10">
          <label className="block text-2xl font-semibold mb-4 text-gray-700">
            Year Range
          </label>
          <Slider
            range
            min={1980}
            max={2024}
            value={yearRange}
            onChange={setYearRange}
            trackStyle={{ backgroundColor: '#146C94' }}
            handleStyle={{ borderColor: '#146C94' }}
            railStyle={{ backgroundColor: '#ddd' }}
          />
          <div className="flex justify-between mt-4 text-lg text-gray-600">
            <span>{yearRange[0]}</span>
            <span>{yearRange[1]}</span>
          </div>
        </div>

        <div className="mb-10">
          <label className="block text-2xl font-semibold mb-4 text-gray-700">
            Desired Profit ($)
          </label>
          <input
            type="number"
            value={desiredPrice}
            onChange={(e) => setDesiredPrice(Number(e.target.value))}
            className="w-full p-4 border rounded-lg shadow-sm text-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
          />
        </div>

        <div className="mb-10">
          <h2 className="text-2xl font-semibold mb-6 text-gray-700">
            Select Auction Types
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
            {auctionTypes.map((type) => (
              <label
                key={type}
                className="flex items-center space-x-3 bg-gray-100 p-4 rounded-lg shadow-sm hover:bg-gray-200 transition duration-200"
              >
                <input
                  type="checkbox"
                  checked={auctionSettings[type]}
                  onChange={() => handleToggleChange(type)}
                  className="form-checkbox h-6 w-6 text-indigo-600"
                  style={{ accentColor: '#146C94' }}
                />
                <span className="text-lg font-medium text-gray-800">{type}</span>
              </label>
            ))}
          </div>
        </div>

        <div className="text-center text-gray-500 mt-8">
          Your settings are saved automatically.
        </div>
      </div>
    </div>
  );
};

export default VehicleFilter;
