export const modelsByMake = {
    Toyota: ["Camry", "Corolla", "Prius", "RAV4", "Highlander", "Tacoma", "Tundra", "Sienna", "Yaris", "Avalon"],
    Honda: ["Civic", "Accord", "CR-V", "Pilot", "Odyssey", "Fit", "Insight", "HR-V", "Passport", "Ridgeline"],
    Ford: ["F-150", "Mustang", "Escape", "Explorer", "Edge", "Expedition", "Fusion", "Ranger", "Bronco", "Focus"],
    Chevrolet: ["Silverado", "Malibu", "Equinox", "Tahoe", "Suburban", "Colorado", "Camaro", "Traverse", "Blazer", "Impala"],
    Nissan: ["Altima", "Sentra", "Maxima", "Rogue", "Murano", "Pathfinder", "Frontier", "Titan", "Versa", "Armada"],
    BMW: ["3 Series", "5 Series", "7 Series", "X1", "X3", "X5", "X7", "Z4", "M3", "i8"],
    MercedesBenz: ["C-Class", "E-Class", "S-Class", "GLA", "GLC", "GLE", "GLS", "A-Class", "B-Class", "G-Class"],
    Audi: ["A3", "A4", "A6", "A8", "Q3", "Q5", "Q7", "Q8", "TT", "R8"],
    Volkswagen: ["Golf", "Jetta", "Passat", "Tiguan", "Atlas", "Beetle", "Arteon", "ID.4", "Touareg", "Eos"],
    Hyundai: ["Elantra", "Sonata", "Tucson", "Santa Fe", "Palisade", "Kona", "Veloster", "Ioniq", "Accent", "Genesis"],
    Kia: ["Forte", "Optima", "Sorento", "Sportage", "Telluride", "Soul", "Stinger", "Rio", "Cadenza", "Niro"],
    Subaru: ["Impreza", "Legacy", "Outback", "Forester", "Crosstrek", "Ascent", "BRZ", "WRX", "Baja", "Tribeca"],
    Mazda: ["Mazda3", "Mazda6", "CX-3", "CX-5", "CX-9", "MX-5 Miata", "RX-8", "Protege", "B-Series", "Millenia", 'Demio'],
    Lexus: ["IS", "ES", "GS", "LS", "NX", "RX", "GX", "LX", "LC", "RC"],
    Acura: ["ILX", "TLX", "RLX", "MDX", "RDX", "NSX", "ZDX", "TSX", "RSX", "Integra"],
    Jeep: ["Wrangler", "Cherokee", "Grand Cherokee", "Compass", "Renegade", "Gladiator", "Patriot", "Liberty", "Commander", "Wagoneer"],
    Dodge: ["Charger", "Challenger", "Durango", "Journey", "Grand Caravan", "Ram 1500", "Ram 2500", "Ram 3500", "Viper", "Nitro"],
    Tesla: ["Model S", "Model 3", "Model X", "Model Y", "Cybertruck", "Roadster"],
    Volvo: ["S60", "S90", "V60", "V90", "XC40", "XC60", "XC90", "C30", "C70", "S80"],
    GMC: ["Sierra", "Canyon", "Yukon", "Terrain", "Acadia", "Savana", "Jimmy", "Envoy", "Sonoma", "Typhoon"],
    LandRover: ["Range Rover", "Range Rover Sport", "Range Rover Evoque", "Discovery", "Defender", "Freelander", "LR2", "LR4"],
    Jaguar: ["XE", "XF", "XJ", "F-Pace", "E-Pace", "I-Pace", "F-Type", "S-Type", "X-Type", "XK"],
    Cadillac: ["ATS", "CTS", "XT4", "XT5", "XT6", "Escalade", "SRX", "XTS", "ELR", "DTS"],
    Porsche: ["911", "Cayenne", "Panamera", "Macan", "Taycan", "Boxster", "Cayman", "Carrera GT"],
    Ferrari: ["488", "812 Superfast", "California", "Portofino", "Roma", "GTC4Lusso", "F8 Tributo", "SF90 Stradale"],
    Lamborghini: ["Huracan", "Aventador", "Urus", "Gallardo", "Murcielago", "Diablo", "Reventon", "Veneno"],
    Maserati: ["Ghibli", "Quattroporte", "Levante", "GranTurismo", "GranCabrio", "MC20"],
    RollsRoyce: ["Ghost", "Phantom", "Wraith", "Dawn", "Cullinan", "Silver Seraph"],
    Bentley: ["Continental", "Flying Spur", "Bentayga", "Mulsanne", "Arnage", "Brooklands"],
    AstonMartin: ["DB11", "Vantage", "DBS", "Rapide", "Vanquish", "DB9", "One-77", "Vulcan"],
    AlfaRomeo: ["Giulia", "Stelvio", "4C", "8C", "GTV", "Spider", "MiTo", "Brera", "159"],
    Infiniti: ["Q50", "Q60", "Q70", "QX30", "QX50", "QX60", "QX80", "G35", "G37", "EX35"],
    Lincoln: ["MKZ", "MKC", "MKX", "Nautilus", "Navigator", "Aviator", "Corsair", "Town Car", "Continental"],
    Mini: ["Cooper", "Cooper S", "Countryman", "Clubman", "Paceman", "Cabrio", "Roadster", "Coupe"],
    Mitsubishi: ["Outlander", "Eclipse Cross", "Pajero", "Montero", "Lancer", "Mirage", "ASX", "Galant"],
    Buick: ["Enclave", "Encore", "Envision", "LaCrosse", "Regal", "Verano", "Riviera", "LeSabre", "Lucerne"],
    Chrysler: ["300", "Pacifica", "Voyager", "Aspen", "Sebring", "Concorde", "PT Cruiser"],
    Fiat: ["500", "500X", "500L", "124 Spider", "Panda", "Tipo", "Ducato", "Punto"],
    Genesis: ["G70", "G80", "G90", "GV70", "GV80"],
};
