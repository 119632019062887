import React, { useState } from 'react';
import { getAuth, updateEmail } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword } from 'firebase/auth';

const ChangeEmail = () => {
  const [newEmail, setNewEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleChangeEmail = async (e) => {
    e.preventDefault();
    const auth = getAuth();
    const user = auth.currentUser;
    try {
      // You need to reauthenticate the user before updating email
      // Assuming you have a reauthenticate function
      await signInWithEmailAndPassword(auth, user.email, password);
      await updateEmail(user, newEmail);
      console.log("Email updated successfully");
      navigate('/settings');  // Navigate back to the settings page
    } catch (error) {
      console.error("Error updating email: ", error);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="bg-white p-8 md:p-12 rounded-lg shadow-md w-full max-w-md mt-8 md:mt-0 mx-3">
        <h2 className="text-2xl font-bold mb-6 text-center text-gray-800">Change Email</h2>
        <form onSubmit={handleChangeEmail}>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="newEmail">
              New Email
            </label>
            <input 
              type="email" 
              id="newEmail" 
              value={newEmail} 
              onChange={(e) => setNewEmail(e.target.value)} 
              placeholder="New Email" 
              className="w-full px-3 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:border-primary" 
            />
          </div>
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
              Password
            </label>
            <input 
              type="password" 
              id="password" 
              value={password} 
              onChange={(e) => setPassword(e.target.value)} 
              placeholder="Password" 
              className="w-full px-3 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:border-primary" 
            />
          </div>
          <div className="flex items-center justify-between">
            <button 
              type="submit" 
              className="bg-primary text-white px-4 py-2 rounded-lg hover:bg-opacity-90 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-opacity-50"
            >
              Update Email
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ChangeEmail;
