import React, { useState, useEffect } from 'react';
import { getAuth, signOut } from 'firebase/auth';
import { getDatabase, ref, get, remove, set } from 'firebase/database';
import { useNavigate, Link } from 'react-router-dom';
import Loading from './../components/Loading';

const Settings = () => {
  const [userRegion, setUserRegion] = useState('');
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const auth = getAuth();

  // Notification Type Toggles
  const [webNotifications, setWebNotifications] = useState(false);
  const [emailNotifications, setEmailNotifications] = useState(false);

  // Classified Listings Toggles
  const [classifiedEnabled, setClassifiedEnabled] = useState(false);
  const [classifiedTurnersEnabled, setClassifiedTurnersEnabled] = useState(false);
  const [classifiedTradeMeEnabled, setClassifiedTradeMeEnabled] = useState(false);
  const [classifiedFacebookEnabled, setClassifiedFacebookEnabled] = useState(false);
  const [classifiedTradeMeUndervalued, setClassifiedTradeMeUndervalued] = useState(false);

  // Auction Listings Toggles
  const [auctionEnabled, setAuctionEnabled] = useState(false);

  // Turners Auctions Toggles
  const [auctionTurnersEnabled, setAuctionTurnersEnabled] = useState(false);
  const [auctionTurnersGeneralEnabled, setAuctionTurnersGeneralEnabled] = useState(false);
  const [auctionTurnersEOLEnabled, setAuctionTurnersEOLEnabled] = useState(false);
  const [auctionTurnersTendersEnabled, setAuctionTurnersTendersEnabled] = useState(false);
  const [auctionTurnersOnlineEnabled, setAuctionTurnersOnlineEnabled] = useState(false);

  // Trade Me Auctions Toggles
  const [auctionTradeMeEnabled, setAuctionTradeMeEnabled] = useState(false);
  const [auctionTradeMeReserveMetEnabled, setAuctionTradeMeReserveMetEnabled] = useState(false);
  const [auctionTradeMeReserveNotMetEnabled, setAuctionTradeMeReserveNotMetEnabled] = useState(false);

  useEffect(() => {
    const fetchUserSettings = async () => {
      const user = auth.currentUser;
      if (!user) {
        navigate('/login');
        return;
      }
      const db = getDatabase();

      // References
      const regionRef = ref(db, `users/${user.uid}/settings/user_region`);

      // Notification toggles
      const webRef = ref(db, `users/${user.uid}/settings/notifications/web`);
      const emailRef = ref(db, `users/${user.uid}/settings/notifications/email`);

      // Classified Listings
      const classifiedEnabledRef = ref(db, `users/${user.uid}/settings/notifications/classified/enabled`);
      const classifiedTurnersRef = ref(db, `users/${user.uid}/settings/notifications/classified/turners`);
      const classifiedTradeMeRef = ref(db, `users/${user.uid}/settings/notifications/classified/trade_me`);
      const classifiedFacebookRef = ref(db, `users/${user.uid}/settings/notifications/classified/facebook`);
      const classifiedTradeMeUndervaluedRef = ref(db, `users/${user.uid}/settings/notifications/classified/trade_me/undervalued`);

      // Auction Listings
      const auctionEnabledRef = ref(db, `users/${user.uid}/settings/notifications/auction/enabled`);

      // Turners Auctions
      const auctionTurnersEnabledRef = ref(db, `users/${user.uid}/settings/notifications/auction/turners/enabled`);
      const auctionTurnersGeneralRef = ref(db, `users/${user.uid}/settings/notifications/auction/turners/general`);
      const auctionTurnersEOLRef = ref(db, `users/${user.uid}/settings/notifications/auction/turners/eol`);
      const auctionTurnersTendersRef = ref(db, `users/${user.uid}/settings/notifications/auction/turners/tenders`);
      const auctionTurnersOnlineRef = ref(db, `users/${user.uid}/settings/notifications/auction/turners/online_auction`);

      // Trade Me Auctions
      const auctionTradeMeEnabledRef = ref(db, `users/${user.uid}/settings/notifications/auction/trade_me/enabled`);
      const auctionTradeMeReserveMetRef = ref(db, `users/${user.uid}/settings/notifications/auction/trade_me/reserve_met`);
      const auctionTradeMeReserveNotMetRef = ref(db, `users/${user.uid}/settings/notifications/auction/trade_me/reserve_not_met`);

      // Fetch all settings in parallel
      try {
        const [
          regionSnapshot,
          webSnapshot,
          emailSnapshot,
          classifiedEnabledSnapshot,
          classifiedTurnersSnapshot,
          classifiedTradeMeSnapshot,
          classifiedFacebookSnapshot,
          classifiedTradeMeUndervaluedSnapshot,
          auctionEnabledSnapshot,
          auctionTurnersEnabledSnapshot,
          auctionTurnersGeneralSnapshot,
          auctionTurnersEOLSnapshot,
          auctionTurnersTendersSnapshot,
          auctionTurnersOnlineSnapshot,
          auctionTradeMeEnabledSnapshot,
          auctionTradeMeReserveMetSnapshot,
          auctionTradeMeReserveNotMetSnapshot,
        ] = await Promise.all([
          get(regionRef),
          get(webRef),
          get(emailRef),
          get(classifiedEnabledRef),
          get(classifiedTurnersRef),
          get(classifiedTradeMeRef),
          get(classifiedFacebookRef),
          get(classifiedTradeMeUndervaluedRef),
          get(auctionEnabledRef),
          get(auctionTurnersEnabledRef),
          get(auctionTurnersGeneralRef),
          get(auctionTurnersEOLRef),
          get(auctionTurnersTendersRef),
          get(auctionTurnersOnlineRef),
          get(auctionTradeMeEnabledRef),
          get(auctionTradeMeReserveMetRef),
          get(auctionTradeMeReserveNotMetRef),
        ]);

        // Set state with fetched values
        const region = regionSnapshot.exists() ? regionSnapshot.val() : 'Unknown';
        setUserRegion(region.charAt(0).toUpperCase() + region.slice(1).toLowerCase());

        setWebNotifications(webSnapshot.exists() ? webSnapshot.val() : false);
        setEmailNotifications(emailSnapshot.exists() ? emailSnapshot.val() : false);

        setClassifiedEnabled(classifiedEnabledSnapshot.exists() ? classifiedEnabledSnapshot.val() : false);
        setClassifiedTurnersEnabled(classifiedTurnersSnapshot.exists() ? classifiedTurnersSnapshot.val() : false);
        setClassifiedTradeMeEnabled(classifiedTradeMeSnapshot.exists() ? classifiedTradeMeSnapshot.val() : false);
        setClassifiedFacebookEnabled(classifiedFacebookSnapshot.exists() ? classifiedFacebookSnapshot.val() : false);
        setClassifiedTradeMeUndervalued(classifiedTradeMeUndervaluedSnapshot.exists() ? classifiedTradeMeUndervaluedSnapshot.val() : false);

        setAuctionEnabled(auctionEnabledSnapshot.exists() ? auctionEnabledSnapshot.val() : false);

        setAuctionTurnersEnabled(auctionTurnersEnabledSnapshot.exists() ? auctionTurnersEnabledSnapshot.val() : false);
        setAuctionTurnersGeneralEnabled(auctionTurnersGeneralSnapshot.exists() ? auctionTurnersGeneralSnapshot.val() : false);
        setAuctionTurnersEOLEnabled(auctionTurnersEOLSnapshot.exists() ? auctionTurnersEOLSnapshot.val() : false);
        setAuctionTurnersTendersEnabled(auctionTurnersTendersSnapshot.exists() ? auctionTurnersTendersSnapshot.val() : false);
        setAuctionTurnersOnlineEnabled(auctionTurnersOnlineSnapshot.exists() ? auctionTurnersOnlineSnapshot.val() : false);

        setAuctionTradeMeEnabled(auctionTradeMeEnabledSnapshot.exists() ? auctionTradeMeEnabledSnapshot.val() : false);
        setAuctionTradeMeReserveMetEnabled(auctionTradeMeReserveMetSnapshot.exists() ? auctionTradeMeReserveMetSnapshot.val() : false);
        setAuctionTradeMeReserveNotMetEnabled(auctionTradeMeReserveNotMetSnapshot.exists() ? auctionTradeMeReserveNotMetSnapshot.val() : false);

        setLoading(false);
      } catch (error) {
        console.error('Error fetching user settings:', error);
        setLoading(false);
      }
    };

    fetchUserSettings();
  }, [auth, navigate]);

  const toggleSetting = async (settingPath, value, setter) => {
    const user = auth.currentUser;
    if (!user) return;
    const db = getDatabase();
    const settingRef = ref(db, `users/${user.uid}/settings/notifications/${settingPath}`);
    try {
      await set(settingRef, !value);
      setter(!value);
    } catch (error) {
      console.error(`Error updating setting ${settingPath}:`, error);
    }
  };

  const handleClearData = async () => {
    const user = auth.currentUser;
    if (!user) return;
    const db = getDatabase();
    const listingsRef = ref(db, `users/${user.uid}/listings`);
    try {
      await remove(listingsRef);
      alert('User data cleared.');
    } catch (error) {
      console.error('Error clearing user data:', error);
    }
  };

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        navigate('/login');
      })
      .catch((error) => {
        console.error('Error signing out:', error);
      });
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className="container mx-auto p-4 max-w-lg mt-12">
          <h1 className="text-3xl font-extrabold mb-8 text-center text-gray-800">
            Settings
          </h1>
          {/* <div className="flex justify-between items-center mb-4">
            <h1 className="text-2xl font-bold">Settings</h1>
          </div> */}
          <div className="bg-white shadow-lg rounded-lg p-4 mb-3">
            <div className="flex justify-between items-center mb-2">
              <span className="font-bold">User Region:</span>
              <span>{userRegion}</span>
            </div>
            <div className="flex flex-col space-y-4">
              <Link
                to="/change-email"
                className="text-[#146C94] font-medium bg-gray-100 py-2 px-4 rounded-lg shadow hover:bg-gray-200 text-center transition duration-150"
              >
                Change Email
              </Link>
              <Link
                to="/change-password"
                className="text-[#146C94] font-medium bg-gray-100 py-2 px-4 rounded-lg shadow hover:bg-gray-200 text-center transition duration-150"
              >
                Change Password
              </Link>
            </div>
          </div>

          {/* Notification Methods */}
          <div className="bg-white shadow-md rounded-lg p-6 mb-2">
            <h2 className="text-lg font-bold mb-4">Notification Methods</h2>
            <ToggleSwitch
              label="Web Notifications (Android users only)"
              checked={webNotifications}
              onChange={() => toggleSetting('web', webNotifications, setWebNotifications)}
              rightLabel="On"
            />
            <ToggleSwitch
              label="Email Notifications"
              checked={emailNotifications}
              onChange={() => toggleSetting('email', emailNotifications, setEmailNotifications)}
              rightLabel="On"
            />
          </div>

          {/* Classified Listings */}
          <div className="bg-white shadow-md rounded-lg p-6 mb-2">
            <h2 className="text-lg font-bold mb-4">Classified Notifications</h2>
            <ToggleSwitch
              label="Classified"
              checked={classifiedEnabled}
              onChange={() => toggleSetting('classified/enabled', classifiedEnabled, setClassifiedEnabled)}
              leftLabel="All"
              rightLabel="Undervalued"
            />
            <ToggleSwitch
              label="Trade Me"
              checked={classifiedTradeMeEnabled}
              onChange={() =>
                toggleSetting('classified/trade_me', classifiedTradeMeEnabled, setClassifiedTradeMeEnabled)
              }
              rightLabel="On"
            />
            <ToggleSwitch
              label="Turners"
              checked={classifiedTurnersEnabled}
              onChange={() =>
                toggleSetting('classified/turners', classifiedTurnersEnabled, setClassifiedTurnersEnabled)
              }
              rightLabel="On"
            />
            <ToggleSwitch
              label="Facebook"
              checked={classifiedFacebookEnabled}
              onChange={() =>
                toggleSetting('classified/facebook', classifiedFacebookEnabled, setClassifiedFacebookEnabled)
              }
              rightLabel="On"
            />
          </div>

          {/* Auction Listings */}
          <div className="bg-white shadow-md rounded-lg p-6 mb-2">
            <h2 className="text-lg font-bold mb-4">Auction Notifications</h2>
            {/* Turners Auctions */}
            <ToggleSwitch
                  label="Turners"
                  checked={auctionTurnersEnabled}
                  onChange={() =>
                    toggleSetting('auction/turners/enabled', auctionTurnersEnabled, setAuctionTurnersEnabled)
                  }
                  leftLabel="All"
                  rightLabel="Undervalued"
                />
                <ToggleSwitch
                  label="General"
                  checked={auctionTurnersGeneralEnabled}
                  onChange={() =>
                    toggleSetting(
                      'auction/turners/general',
                      auctionTurnersGeneralEnabled,
                      setAuctionTurnersGeneralEnabled
                    )
                  }
                  rightLabel="On"
                />
                <ToggleSwitch
                  label="End Of Life"
                  checked={auctionTurnersEOLEnabled}
                  onChange={() =>
                    toggleSetting(
                      'auction/turners/eol',
                      auctionTurnersEOLEnabled,
                      setAuctionTurnersEOLEnabled
                    )
                  }
                  rightLabel="On"
                />
                <ToggleSwitch
                  label="Tenders"
                  checked={auctionTurnersTendersEnabled}
                  onChange={() =>
                    toggleSetting(
                      'auction/turners/tenders',
                      auctionTurnersTendersEnabled,
                      setAuctionTurnersTendersEnabled
                    )
                  }
                  rightLabel="On"
                />
                <ToggleSwitch
                  label="Online Auctions"
                  checked={auctionTurnersOnlineEnabled}
                  onChange={() =>
                    toggleSetting(
                      'auction/turners/online_auction',
                      auctionTurnersOnlineEnabled,
                      setAuctionTurnersOnlineEnabled
                    )
                  }
                  rightLabel="On"
                />

                {/* Trade Me Auctions */}
                <ToggleSwitch
                  label="Trade Me"
                  checked={auctionTradeMeEnabled}
                  onChange={() =>
                    toggleSetting('auction/trade_me/enabled', auctionTradeMeEnabled, setAuctionTradeMeEnabled)
                  }
                  leftLabel="All"
                  rightLabel="Undervalued"
              />
              <ToggleSwitch
                    label="Reserve Met / No Reserve"
                    checked={auctionTradeMeReserveMetEnabled}
                    onChange={() =>
                      toggleSetting(
                        'auction/trade_me/reserve_met',
                        auctionTradeMeReserveMetEnabled,
                        setAuctionTradeMeReserveMetEnabled
                      )
                    }
                    rightLabel="On"
                  />
                  <ToggleSwitch
                    label="Reserve Not Met"
                    checked={auctionTradeMeReserveNotMetEnabled}
                    onChange={() =>
                      toggleSetting(
                        'auction/trade_me/reserve_not_met',
                        auctionTradeMeReserveNotMetEnabled,
                        setAuctionTradeMeReserveNotMetEnabled
                      )
                    }
                    rightLabel="On"
                  />
                  
                
          </div>

          <div className="bg-white shadow-lg rounded-lg p-4">
            <button
              onClick={handleClearData}
              className="bg-[#146C94] text-white px-4 py-2 rounded-lg shadow hover:bg-[#125A7A] w-full mb-2 transition duration-150"
            >
              Clear User Data
            </button>
            <button
              onClick={handleLogout}
              className="bg-[#146C94] text-white px-4 py-2 rounded-lg shadow hover:bg-[#125A7A] w-full transition duration-150"
            >
              Logout
            </button>
          </div>
        </div>
      )}
    </>
  );
};

// Reusable ToggleSwitch component
const ToggleSwitch = ({ label, checked, onChange, leftLabel, rightLabel }) => (
  <div className="flex justify-between items-center mb-4">
    <span className="font-semibold text-gray-600">{label}</span>
    <div className="flex items-center space-x-2">
      {leftLabel && <span className="text-gray-500 text-sm">{leftLabel}</span>}
      <label className="relative inline-flex items-center cursor-pointer">
        <input type="checkbox" checked={checked} onChange={onChange} className="sr-only peer" />
        <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-[#146C94] rounded-full peer peer-checked:bg-[#146C94]">
          <span
            className={`${
              checked ? 'translate-x-6' : 'translate-x-1'
            } inline-block w-4 h-4 transform bg-white rounded-full transition-transform`}
          />
        </div>
      </label>
      {rightLabel && <span className="text-gray-500 text-sm">{rightLabel}</span>}
    </div>
  </div>
);

export default Settings;
