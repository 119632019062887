// src/components/SwipeableCard.js
import React from 'react';
import { useSwipeable } from 'react-swipeable';
import { FiX } from 'react-icons/fi';
import PropTypes from 'prop-types';

// Utility function to capitalize the first letter
const capitalizeFirstLetter = (string) => {
  if (!string) return '';
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

const SwipeableCard = ({
  vehicle,
  onMarkAsSeen,
  vehicleProperties,
  getPriceColor,
  priceDifference,
}) => {
  // Swipe handler setup for each card
  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => onMarkAsSeen(vehicle.id),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const priceDiff = (vehicle.medium_estimate || 0) - (vehicle.price || 0);
  const priceColor = getPriceColor(priceDiff, priceDifference);

  return (
    <div
      {...swipeHandlers}
      className="relative bg-white rounded-lg overflow-hidden shadow-lg hover:shadow-2xl transition-shadow duration-300 transform hover:-translate-y-1 hover:scale-105"
    >
      {/* 'X' button */}
      <button
        onClick={() => onMarkAsSeen(vehicle.id)}
        className="absolute top-3 right-3 z-10 bg-gray-800 text-white p-2 rounded-full hover:bg-gray-600 transition-colors focus:outline-none"
        aria-label="Dismiss"
      >
        <FiX size={20} />
      </button>

      <a
        href={vehicle.url}
        target="_blank"
        rel="noopener noreferrer"
        className="block"
      >
        {vehicle.image && (
          <img
            src={vehicle.image}
            alt={`${capitalizeFirstLetter(vehicle.make)} ${capitalizeFirstLetter(vehicle.model)}`}
            className="w-full h-56 object-cover"
          />
        )}
        <div className="p-6 flex flex-col space-y-4">
          {/* Vehicle Title */}
          <h3 className="text-2xl font-semibold text-gray-800">
            {capitalizeFirstLetter(vehicle.make)} {capitalizeFirstLetter(vehicle.model)} ({vehicle.year || 'N/A'})
          </h3>

          {/* Vehicle Properties */}
          <div className="grid grid-cols-2 gap-4 text-gray-700">
            {vehicleProperties.map((prop) => {
              const value = vehicle[prop.key];
              if (value != null && value !== '') {
                return (
                  <p key={prop.key}>
                    <span className="font-medium">{prop.label}:</span>{' '}
                    {prop.format ? prop.format(value) : value}
                  </p>
                );
              }
              return null;
            })}
          </div>

          {/* Valuation Information */}
          {vehicle.low_estimate != null && (
          <div className="mt-2">
            <h4 className="text-lg font-medium text-gray-800 mb-2">
              Valuations
            </h4>
            <div className="flex space-x-2">
              {vehicle.low_estimate != null && (
                <div className="flex-1 bg-yellow-100 text-yellow-900 px-3 py-2 rounded-lg shadow-sm text-center">
                  <span className="block text-sm font-semibold">Low</span>
                  <span className="block text-lg">
                    ${vehicle.low_estimate.toLocaleString()}
                  </span>
                </div>
              )}
              {vehicle.medium_estimate != null && (
                <div className="flex-1 bg-blue-100 text-blue-900 px-3 py-2 rounded-lg shadow-sm text-center">
                  <span className="block text-sm font-semibold">Medium</span>
                  <span className="block text-lg">
                    ${vehicle.medium_estimate.toLocaleString()}
                  </span>
                </div>
              )}
              {vehicle.high_estimate != null && (
                <div className="flex-1 bg-green-100 text-green-900 px-3 py-2 rounded-lg shadow-sm text-center">
                  <span className="block text-sm font-semibold">High</span>
                  <span className="block text-lg">
                    ${vehicle.high_estimate.toLocaleString()}
                  </span>
                </div>
              )}
            </div>
          </div>
          )}

          {/* Price and Estimated Value */}
          <div className="flex justify-between items-center mt-4">
            <div>
              {vehicle.estimated_value && (
                <p className="text-gray-600">
                  Estimated Value: $
                  {vehicle.estimated_value.toLocaleString()}
                </p>
              )}
            </div>
            <div className="text-right">
              <p className={`text-xl font-bold ${priceColor}`}>
                ${vehicle.price?.toLocaleString() || 'N/A'}
              </p>
              {vehicle.medium_estimate && (
                <p className="text-sm text-gray-500">
                  {priceDiff >= 0 ? '+' : ''}
                  {priceDiff.toLocaleString()} from estimate
                </p>
              )}
            </div>
          </div>
        </div>
      </a>
    </div>
  );
};

// PropTypes for validation
SwipeableCard.propTypes = {
  vehicle: PropTypes.shape({
    id: PropTypes.string.isRequired,
    make: PropTypes.string.isRequired,
    model: PropTypes.string.isRequired,
    year: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    image: PropTypes.string,
    url: PropTypes.string.isRequired,
    price: PropTypes.number,
    low_estimate: PropTypes.number,
    medium_estimate: PropTypes.number,
    high_estimate: PropTypes.number,
    estimated_value: PropTypes.number,
  }).isRequired,
  onMarkAsSeen: PropTypes.func.isRequired,
  vehicleProperties: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      format: PropTypes.func,
    })
  ).isRequired,
  getPriceColor: PropTypes.func.isRequired,
  priceDifference: PropTypes.number,
};

export default SwipeableCard;
