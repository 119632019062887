// Import statements
import React, { useState, useEffect, useMemo } from 'react';
import { getDatabase, ref, onValue, get } from 'firebase/database';
import { getAuth } from 'firebase/auth';
import axios from 'axios';
import { useSwipeable } from 'react-swipeable';
import Loading from './../components/Loading';

// Utility functions
const capitalizeFirstLetter = (string) => {
  if (!string) return '';
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const NothingFound = ({ message }) => (
  <div className="flex flex-col items-center justify-center py-16 space-y-4">
    <div className="rounded-full bg-gray-100 p-6">
      <svg
        className="w-12 h-12 text-gray-400"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"
        />
      </svg>
    </div>
    <h2 className="text-2xl font-bold text-gray-700">No Vehicles Found</h2>
    <p className="text-gray-500 text-center max-w-md">{message}</p>
  </div>
);

const weirdWordsList = [
  'boat',
  'yacht',
  'motorbike',
  'motorcycle',
  'bike',
  'shirt',
];

const isSequentialStartingFromOne = (num) => {
  const numStr = num.toString();
  for (let i = 0; i < numStr.length; i++) {
    if (parseInt(numStr[i]) !== i + 1) {
      return false;
    }
  }
  return true;
};

// Custom timestamp parser
const parseTimestamp = (timestamp) => {
  if (!timestamp) return null;
  const [datePart, timePart] = timestamp.split(' ');
  const [year, month, day] = datePart.split('-').map(Number);
  const [hour, minute, second] = timePart
    ? timePart.split(':').map(Number)
    : [0, 0, 0];
  // Months in JavaScript Date are zero-based (0-11)
  return new Date(year, month - 1, day, hour, minute, second).getTime();
};

// Main component
const VehicleList = () => {
  // State declarations
  const [refreshKey, setRefreshKey] = useState(0);
  const [vehicles, setVehicles] = useState([]);
  const [filterSettings, setFilterSettings] = useState({});
  const [deletingIds, setDeletingIds] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState('all');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [sortConfig, setSortConfig] = useState({
    key: 'timestamp',
    direction: 'descending',
  });

  // Filters state
  const [filters, setFilters] = useState({
    make: '',
    model: '',
    location: '',
    minYear: '',
    maxYear: '',
    minPrice: '',
    maxPrice: '',
    hideNoPrice: true,
    hideInactive: true,
    onlyRecommended: false,
    listingType: '',
    site: '',
    hideWeirdPrices: true,
    hideWeirdListings: true,
    minTimestamp: '',
    maxTimestamp: '',
  });

  // View state: 'card' or 'table'
  const [view, setView] = useState('card');

  // State for hiding seen listings
  const [hideSeen, setHideSeen] = useState(true);
  const [seenListings, setSeenListings] = useState([]);

  // State for selected vehicles
  const [selectedVehicleIds, setSelectedVehicleIds] = useState([]);

  // Firebase setup
  const db = getDatabase();
  const user = getAuth().currentUser;
  const apiKeyRef = ref(db, `users/${user.uid}/settings/db_api_key`);
  const filterSettingsRef = ref(db, `users/${user.uid}/filter/classified/`);
  const userRegionRef = ref(db, `users/${user.uid}/settings/user_region`); 

  // Reset currentPage when filters or sort change
  useEffect(() => {
    setCurrentPage(1);
  }, [filters, filter, sortConfig]);

  // Fetch data
  useEffect(() => {
    const fetchFilterSettings = () => {
      onValue(filterSettingsRef, (snapshot) => {
        const data = snapshot.val();
        setFilterSettings(data || {});
      });
    };

    const fetchUserRegion = async () => {
      const userRegion = await get(userRegionRef).then((snapshot) => snapshot.val());
      if (userRegion) {
        setFilters((prevFilters) => ({
          ...prevFilters,
          location: capitalizeFirstLetter(userRegion),
        }));
      }
    };

    const fetchVehicles = async () => {
      try {
        const apiKey = await get(apiKeyRef).then((snapshot) => snapshot.val());
        const response = await axios.get(
          'https://api.harryludemann.com/api/v1/secure/listings',
          { headers: { 'X-API-KEY': apiKey } }
        );
        const vehicleList = response.data.map((vehicle) => ({
          ...vehicle,
          price: vehicle.price ? parseFloat(vehicle.price) : null,
          year: vehicle.year ? parseInt(vehicle.year) : null,
          odometer: vehicle.odometer ? parseInt(vehicle.odometer) : null,
          engine_size: vehicle.engine_size
            ? parseInt(vehicle.engine_size)
            : null,
          low_estimate: vehicle.low_estimate
            ? parseFloat(vehicle.low_estimate)
            : null,
          medium_estimate: vehicle.medium_estimate
            ? parseFloat(vehicle.medium_estimate)
            : null,
          high_estimate: vehicle.high_estimate
            ? parseFloat(vehicle.high_estimate)
            : null,
          // Parse timestamp into milliseconds
          timestamp: vehicle.timestamp
            ? parseTimestamp(vehicle.timestamp)
            : null,
        }));
        setVehicles(vehicleList);
      } catch (error) {
        console.error('Error fetching vehicles:', error);
      } finally {
        setLoading(false);
      }
    };

    const fetchSeenListings = async () => {
      try {
        const apiKey = await get(apiKeyRef).then((snapshot) => snapshot.val());
        const response = await axios.get(
          'https://api.harryludemann.com/api/v1/secure/seen/',
          { headers: { 'X-API-KEY': apiKey } }
        );
        const seenIds = response.data.map((item) => item.listing_id);
        setSeenListings(seenIds);
      } catch (error) {
        console.error('Error fetching seen listings:', error);
      }
    };

    fetchFilterSettings();
    fetchVehicles();
    fetchSeenListings();
    fetchUserRegion();
  }, [refreshKey]);

  // Filter function
  const filterVehicles = () => {
    return vehicles.filter((vehicle) => {
      // Apply hideSeen filter
      if (hideSeen && seenListings.includes(vehicle.id)) {
        return false;
      }

      // Apply make filter
      if (filters.make) {
        const makeFilters = filters.make
          .split(',')
          .map((s) => s.trim().toLowerCase());
        if (
          !vehicle.make ||
          !makeFilters.some((mk) => vehicle.make.toLowerCase().includes(mk))
        ) {
          return false;
        }
      }

      // Apply model filter
      if (filters.model) {
        const modelFilters = filters.model
          .split(',')
          .map((s) => s.trim().toLowerCase());
        if (
          !vehicle.model ||
          !modelFilters.some((md) =>
            vehicle.model.toLowerCase().includes(md)
          )
        ) {
          return false;
        }
      }

      // Apply location filter
      if (filters.location) {
        const locationFilters = filters.location
          .split(',')
          .map((s) => s.trim().toLowerCase());
        if (
          !vehicle.location ||
          !locationFilters.some((loc) =>
            vehicle.location.toLowerCase().includes(loc)
          )
        ) {
          return false;
        }
      }

      // Apply year range filter
      if (
        filters.minYear &&
        (!vehicle.year || vehicle.year < parseInt(filters.minYear))
      ) {
        return false;
      }
      if (
        filters.maxYear &&
        (!vehicle.year || vehicle.year > parseInt(filters.maxYear))
      ) {
        return false;
      }

      // Apply price range filter
      if (
        filters.minPrice &&
        (!vehicle.price || vehicle.price < parseInt(filters.minPrice))
      ) {
        return false;
      }
      if (
        filters.maxPrice &&
        (!vehicle.price || vehicle.price > parseInt(filters.maxPrice))
      ) {
        return false;
      }

      // Apply hideNoPrice
      if (filters.hideNoPrice && (!vehicle.price || vehicle.price <= 0)) {
        return false;
      }

      // Apply hideInactive
      if (filters.hideInactive && !vehicle.active) {
        return false;
      }

      // Apply onlyRecommended filter
      if (filters.onlyRecommended && !vehicle.should_buy) {
        return false;
      }

      // Apply listingType filter
      if (
        filters.listingType &&
        vehicle.listing_type !== filters.listingType
      ) {
        return false;
      }

      // Apply site filter
      if (filters.site && vehicle.site !== filters.site) {
        return false;
      }

      // Apply hideWeirdPrices
      if (filters.hideWeirdPrices && vehicle.price != null) {
        if (
          vehicle.price < 500 ||
          isSequentialStartingFromOne(vehicle.price)
        ) {
          return false;
        }
      }

      // Apply timestamp filters
      if (filters.minTimestamp || filters.maxTimestamp) {
        if (!vehicle.timestamp) {
          return false;
        }
        const vehicleTimestamp = vehicle.timestamp;
        if (filters.minTimestamp) {
          const minTimestamp = new Date(filters.minTimestamp).getTime();
          if (vehicleTimestamp < minTimestamp) {
            return false;
          }
        }
        if (filters.maxTimestamp) {
          const maxTimestamp =
            new Date(filters.maxTimestamp).getTime() +
            24 * 60 * 60 * 1000 -
            1; // Include the entire day
          if (vehicleTimestamp > maxTimestamp) {
            return false;
          }
        }
      }

      // Apply hideWeirdListings
      if (filters.hideWeirdListings) {
        const contentToSearch = [
          vehicle.make,
          vehicle.model,
          vehicle.description,
          vehicle.location,
          vehicle.title,
          vehicle.listing_type,
          vehicle.site,
        ]
          .filter(Boolean)
          .join(' ')
          .toLowerCase();

        if (
          weirdWordsList.some((word) =>
            contentToSearch.includes(word.toLowerCase())
          )
        ) {
          return false;
        }
      }

      // Existing filters based on 'filter'
      if (filter === 'all') return true;

      const { desiredPrice = 1000 } = filterSettings;

      const hasValuationData =
        vehicle.medium_estimate && vehicle.medium_estimate > 0;
      const priceDifference = vehicle.medium_estimate - vehicle.price;
      const meetsFilter = priceDifference >= desiredPrice;

      switch (filter) {
        case 'found':
          return meetsFilter && hasValuationData;
        case 'failed':
          return !meetsFilter && hasValuationData;
        case 'unevaluated':
          return !hasValuationData;
        default:
          return true;
      }
    });
  };

  // Memoized sorted vehicles
  const sortedVehicles = useMemo(() => {
    let sortableVehicles = [...filterVehicles()];
    if (sortConfig !== null) {
      sortableVehicles.sort((a, b) => {
        let aValue = a[sortConfig.key];
        let bValue = b[sortConfig.key];

        if (sortConfig.key === 'timestamp') {
          if (aValue === null && bValue === null) {
            return 0;
          } else if (aValue === null) {
            return 1; // Null timestamps go to the bottom
          } else if (bValue === null) {
            return -1; // Null timestamps go to the bottom
          } else {
            if (aValue < bValue) {
              return sortConfig.direction === 'ascending' ? -1 : 1;
            }
            if (aValue > bValue) {
              return sortConfig.direction === 'ascending' ? 1 : -1;
            }
            return 0;
          }
        } else {
          if (aValue == null) aValue = '';
          if (bValue == null) bValue = '';

          if (typeof aValue === 'string') {
            aValue = aValue.toLowerCase();
          }
          if (typeof bValue === 'string') {
            bValue = bValue.toLowerCase();
          }

          if (aValue < bValue) {
            return sortConfig.direction === 'ascending' ? -1 : 1;
          }
          if (aValue > bValue) {
            return sortConfig.direction === 'ascending' ? 1 : -1;
          }
          return 0;
        }
      });
    }
    return sortableVehicles;
  }, [
    vehicles,
    filter,
    filterSettings,
    sortConfig,
    filters,
    hideSeen,
    seenListings,
  ]);

  // Pagination
  const paginatedVehicles = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    return sortedVehicles.slice(startIndex, startIndex + itemsPerPage);
  }, [sortedVehicles, currentPage, itemsPerPage]);

  const totalPages = useMemo(
    () => Math.ceil(sortedVehicles.length / itemsPerPage),
    [sortedVehicles, itemsPerPage]
  );

  // Handlers
  const handleMarkAsSeen = async (id) => {
    try {
      const apiKey = await get(apiKeyRef).then((snapshot) => snapshot.val());
      await axios.post(
        'https://api.harryludemann.com/api/v1/secure/seen/',
        {
          listing_id: id,
        },
        { headers: { 'X-API-KEY': apiKey } }
      );
      // Update the seenListings array
      setSeenListings((prevSeenListings) => [...prevSeenListings, id]);
    } catch (error) {
      console.error('Error marking vehicle as seen:', error);
    }
  };

  const getNoVehiclesMessage = () => {
    switch (filter) {
      case 'found':
        return 'No vehicles are currently below market value. Try adjusting your filters and check back later.';
      case 'failed':
        return 'No vehicles are currently above market value. Try adjusting your filters and check back later.';
      case 'unevaluated':
        return 'No unappraised vehicles found.';
      default:
        return 'Try adjusting your filters or check back later.';
    }
  };

  const handleMarkAllAsSeen = async () => {
    try {
      const apiKey = await get(apiKeyRef).then((snapshot) => snapshot.val());
      const listingIds = sortedVehicles.map((vehicle) => vehicle.id);

      await axios.post(
        'https://api.harryludemann.com/api/v1/secure/seen/batch/',
        {
          listing_ids: listingIds,
        },
        { headers: { 'X-API-KEY': apiKey } }
      );

      // Update the seenListings state
      setSeenListings((prevSeenListings) => [
        ...prevSeenListings,
        ...listingIds,
      ]);
    } catch (error) {
      console.error('Error marking all vehicles as seen:', error);
    }
  };

  const handleMarkSelectedAsSeen = async () => {
    try {
      const apiKey = await get(apiKeyRef).then((snapshot) => snapshot.val());

      await axios.post(
        'https://api.harryludemann.com/api/v1/secure/seen/batch/',
        {
          listing_ids: selectedVehicleIds,
        },
        { headers: { 'X-API-KEY': apiKey } }
      );

      // Update the seenListings state
      setSeenListings((prevSeenListings) => [
        ...prevSeenListings,
        ...selectedVehicleIds,
      ]);

      // Clear selectedVehicleIds
      setSelectedVehicleIds([]);
    } catch (error) {
      console.error('Error marking selected vehicles as seen:', error);
    }
  };

  const renderVehicleDetails = (vehicle) => (
    <div className="grid grid-cols-2 gap-4 mt-4 text-gray-600">
      {[
        { label: 'Make', value: vehicle.make },
        { label: 'Model', value: vehicle.model },
        { label: 'Year', value: vehicle.year },
        {
          label: 'Odometer',
          value: vehicle.odometer
            ? `${vehicle.odometer.toLocaleString()} km`
            : null,
        },
        {
          label: 'Engine Size',
          value: vehicle.engine_size ? `${vehicle.engine_size} cc` : null,
        },
        { label: 'Location', value: vehicle.location },
        { label: 'Registration', value: vehicle.registration },
        { label: 'WOF', value: vehicle.wof },
        { label: 'Plate', value: vehicle.plate },
        { label: 'Site', value: vehicle.site },
        { label: 'Listing Type', value: vehicle.listing_type },
        { label: 'High Estimate', value: vehicle.high_estimate },
        { label: 'Medium Estimate', value: vehicle.medium_estimate },
        { label: 'Low Estimate', value: vehicle.low_estimate },
        // { label: 'Description', value: vehicle.description },
      ]
        .filter(({ value }) => value != null) // Filter out null values
        .map(({ label, value }) => (
          <div key={label}>
            <p className="font-medium text-gray-700">{label}</p>
            <p>{value}</p>
          </div>
        ))}
    </div>
  );  

  const SwipeableVehicle = ({ vehicle }) => {
    const handlers = useSwipeable({
      onSwipedLeft: async () => {
        setDeletingIds((prev) => [...prev, vehicle.id]);
        await handleMarkAsSeen(vehicle.id);
      },
    });

    return (
      <div
        className={`bg-white rounded-lg shadow-md overflow-hidden transform transition-all duration-300 hover:shadow-lg cursor-pointer ${
          deletingIds.includes(vehicle.id)
            ? 'translate-x-full opacity-0'
            : ''
        }`}
        {...handlers}
        onClick={() =>
          window.open(vehicle.url, '_blank', 'noopener noreferrer')
        }
      >
        {vehicle.image && (
          <div className="relative h-48 overflow-hidden">
            <img
              src={vehicle.image}
              alt={vehicle.model}
              className="w-full h-full object-cover transform transition-transform duration-300 hover:scale-105"
            />
          </div>
        )}
        <div className="p-6 relative">
          {/* Delete button */}
          <button
            className="absolute top-2 right-2 text-gray-500 hover:text-red-500"
            onClick={async (e) => {
              e.stopPropagation(); // Prevent the click from opening the link
              setDeletingIds((prev) => [...prev, vehicle.id]);
              await handleMarkAsSeen(vehicle.id);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>

          <div className="flex justify-between items-center mb-4">
            <h3 className="text-xl font-semibold text-gray-800">
              {capitalizeFirstLetter(vehicle.make)}{' '}
              {capitalizeFirstLetter(vehicle.model)}{' '}
              {vehicle.year && `(${vehicle.year})`}
            </h3>
            <span className="text-xl font-semibold text-gray-800 mr-4">
              {vehicle.price ? `$${vehicle.price.toLocaleString()}` : 'N/A'}
            </span>
          </div>
          {renderVehicleDetails(vehicle)}
          {/* Price Estimates */}
          {(vehicle.low_estimate ||
            vehicle.medium_estimate ||
            vehicle.high_estimate) && (
            <div className="mt-4">
              <h4 className="text-lg font-medium text-gray-700">
                Price Estimates
              </h4>
              <div className="grid grid-cols-3 gap-4 mt-2 text-gray-600">
                {[
                  {
                    label: 'Low Estimate',
                    value: vehicle.low_estimate
                      ? `$${vehicle.low_estimate.toLocaleString()}`
                      : 'N/A',
                  },
                  {
                    label: 'Medium Estimate',
                    value: vehicle.medium_estimate
                      ? `$${vehicle.medium_estimate.toLocaleString()}`
                      : 'N/A',
                  },
                  {
                    label: 'High Estimate',
                    value: vehicle.high_estimate
                      ? `$${vehicle.high_estimate.toLocaleString()}`
                      : 'N/A',
                  },
                ].map(({ label, value }) => (
                  <div key={label}>
                    <p className="font-medium text-gray-700">{label}</p>
                    <p>{value}</p>
                  </div>
                ))}
              </div>
            </div>
          )}
          {/* Should Buy */}
          {vehicle.should_buy !== null && (
            <div className="mt-4">
              <h4 className="text-lg font-medium text-gray-700">
                Recommendation
              </h4>
              <p
                className={`font-semibold ${
                  vehicle.should_buy ? 'text-green-600' : 'text-red-600'
                }`}
              >
                {vehicle.should_buy
                  ? 'This vehicle is recommended to buy.'
                  : 'This vehicle is not recommended to buy.'}
              </p>
            </div>
          )}
        </div>
      </div>
    );
  };

  // Table view component
  const TableView = () => {
    // Handlers for selecting vehicles
    const areAllSelected = paginatedVehicles.every((vehicle) =>
      selectedVehicleIds.includes(vehicle.id)
    );

    const handleSelectAll = () => {
      if (areAllSelected) {
        // Unselect all
        setSelectedVehicleIds([]);
      } else {
        // Select all on the current page
        const newSelectedIds = paginatedVehicles.map((vehicle) => vehicle.id);
        setSelectedVehicleIds(newSelectedIds);
      }
    };

    const handleSelect = (id) => {
      setSelectedVehicleIds((prevSelectedIds) =>
        prevSelectedIds.includes(id)
          ? prevSelectedIds.filter((selectedId) => selectedId !== id)
          : [...prevSelectedIds, id]
      );
    };

    return (
      <div className="overflow-x-auto bg-white rounded-lg shadow-md">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              {/* Select All Checkbox */}
              <th className="px-6 py-3">
                <input
                  type="checkbox"
                  onChange={handleSelectAll}
                  checked={areAllSelected}
                />
              </th>
              {/* Columns */}
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Make
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Model
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Year
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Price
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Location
              </th>
              <th scope="col" className="relative px-6 py-3">
                <span className="sr-only">Seen</span>
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {paginatedVehicles.map((vehicle) => (
              <tr key={vehicle.id}>
                {/* Row Checkbox */}
                <td className="px-6 py-4 whitespace-nowrap">
                  <input
                    type="checkbox"
                    onChange={() => handleSelect(vehicle.id)}
                    checked={selectedVehicleIds.includes(vehicle.id)}
                  />
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {capitalizeFirstLetter(vehicle.make)}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {capitalizeFirstLetter(vehicle.model)}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {vehicle.year || 'N/A'}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {vehicle.price
                    ? `$${vehicle.price.toLocaleString()}`
                    : 'N/A'}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {vehicle.location || 'N/A'}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                  <button
                    onClick={() => handleMarkAsSeen(vehicle.id)}
                    className="text-red-600 hover:text-red-900"
                  >
                    Seen
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {paginatedVehicles.length === 0 && (
          <div className="p-6">
            <NothingFound message={getNoVehiclesMessage()} />
          </div>
        )}
      </div>
    );
  };

  // Render
  return (
    <div className="min-h-screen bg-gray-50 pt-10">
      <div className="container mx-auto px-4 py-8">
        {loading ? (
          <Loading />
        ) : (
          <div className="space-y-8">
            <div className="space-y-6">
              {/* Filters and Sort Options */}
              <div className="bg-white p-4 sm:p-6 rounded-lg shadow-md">
                {/* View Toggle and Mark As Seen Buttons */}
                <div className="flex flex-col sm:flex-row items-start sm:items-center space-y-2 sm:space-y-0 sm:space-x-2 mb-4 justify-between">
                  <h2 className="text-3xl font-extrabold text-gray-800 w-full sm:w-auto">
                    Vehicle List
                  </h2>
                  <div className="flex flex-col sm:flex-row flex-wrap items-start sm:items-center space-y-2 sm:space-y-0 sm:space-x-2 w-full sm:w-auto justify-end">
                    {/* View Toggle Buttons */}
                    <button
                      onClick={() => setView('card')}
                      style={{
                        backgroundColor: view === 'card' ? '#146C94' : '',
                      }}
                      className={`w-full sm:w-auto text-center px-4 py-2 rounded-md ${
                        view === 'card'
                          ? 'text-white'
                          : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                      }`}
                    >
                      Card View
                    </button>
                    <button
                      onClick={() => setView('table')}
                      style={{
                        backgroundColor: view !== 'card' ? '#146C94' : '',
                      }}
                      className={`w-full sm:w-auto text-center px-4 py-2 rounded-md ${
                        view === 'table'
                          ? 'text-white'
                          : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                      }`}
                    >
                      Table View
                    </button>
                    {/* Mark All As Seen Button */}
                    <button
                      onClick={handleMarkAllAsSeen}
                      className="w-full sm:w-auto text-center px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600"
                    >
                      Mark All As Seen
                    </button>
                    {/* Mark Selected As Seen Button */}
                    {selectedVehicleIds.length > 0 && (
                      <button
                        onClick={handleMarkSelectedAsSeen}
                        className="w-full sm:w-auto text-center px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600"
                      >
                        Mark Selected As Seen
                      </button>
                    )}
                  </div>
                </div>
                {/* Filters */}
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                  {/* Make Filter */}
                  <div className="flex flex-col">
                    <label
                      htmlFor="make"
                      className="text-gray-700 font-medium mb-1"
                    >
                      Make
                    </label>
                    <input
                      id="make"
                      type="text"
                      value={filters.make}
                      onChange={(e) =>
                        setFilters({ ...filters, make: e.target.value })
                      }
                      placeholder="e.g., Toyota, Mazda"
                      className="bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 px-3 py-2"
                    />
                  </div>
                  {/* Model Filter */}
                  <div className="flex flex-col">
                    <label
                      htmlFor="model"
                      className="text-gray-700 font-medium mb-1"
                    >
                      Model
                    </label>
                    <input
                      id="model"
                      type="text"
                      value={filters.model}
                      onChange={(e) =>
                        setFilters({ ...filters, model: e.target.value })
                      }
                      placeholder="e.g., Corolla, Civic"
                      className="bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 px-3 py-2"
                    />
                  </div>
                  {/* Location Filter */}
                  <div className="flex flex-col">
                    <label
                      htmlFor="location"
                      className="text-gray-700 font-medium mb-1"
                    >
                      Location
                    </label>
                    <input
                      id="location"
                      type="text"
                      value={filters.location}
                      onChange={(e) =>
                        setFilters({ ...filters, location: e.target.value })
                      }
                      placeholder="e.g., Auckland, Wellington"
                      className="bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 px-3 py-2"
                    />
                  </div>
                  {/* Year Range Filter */}
                  <div className="flex flex-col">
                    <label
                      htmlFor="yearRange"
                      className="text-gray-700 font-medium mb-1"
                    >
                      Year Range
                    </label>
                    <div className="flex space-x-2">
                      <input
                        id="minYear"
                        type="number"
                        placeholder="Min"
                        value={filters.minYear}
                        onChange={(e) =>
                          setFilters({ ...filters, minYear: e.target.value })
                        }
                        className="bg-white border border-gray-300 rounded-md shadow-sm w-full focus:outline-none focus:ring-blue-500 focus:border-blue-500 px-3 py-2"
                      />
                      <input
                        id="maxYear"
                        type="number"
                        placeholder="Max"
                        value={filters.maxYear}
                        onChange={(e) =>
                          setFilters({ ...filters, maxYear: e.target.value })
                        }
                        className="bg-white border border-gray-300 rounded-md shadow-sm w-full focus:outline-none focus:ring-blue-500 focus:border-blue-500 px-3 py-2"
                      />
                    </div>
                  </div>
                  {/* Price Range Filter */}
                  <div className="flex flex-col">
                    <label
                      htmlFor="priceRange"
                      className="text-gray-700 font-medium mb-1"
                    >
                      Price Range
                    </label>
                    <div className="flex space-x-2">
                      <input
                        id="minPrice"
                        type="number"
                        placeholder="Min"
                        value={filters.minPrice}
                        onChange={(e) =>
                          setFilters({ ...filters, minPrice: e.target.value })
                        }
                        className="bg-white border border-gray-300 rounded-md shadow-sm w-full focus:outline-none focus:ring-blue-500 focus:border-blue-500 px-3 py-2"
                      />
                      <input
                        id="maxPrice"
                        type="number"
                        placeholder="Max"
                        value={filters.maxPrice}
                        onChange={(e) =>
                          setFilters({ ...filters, maxPrice: e.target.value })
                        }
                        className="bg-white border border-gray-300 rounded-md shadow-sm w-full focus:outline-none focus:ring-blue-500 focus:border-blue-500 px-3 py-2"
                      />
                    </div>
                  </div>
                  {/* Timestamp Range Filter */}
                  <div className="flex flex-col">
                    <label
                      htmlFor="timestampRange"
                      className="text-gray-700 font-medium mb-1"
                    >
                      Timestamp Range
                    </label>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
                      <input
                        id="minTimestamp"
                        type="date"
                        value={filters.minTimestamp}
                        onChange={(e) =>
                          setFilters({ ...filters, minTimestamp: e.target.value })
                        }
                        className="bg-white border border-gray-300 rounded-md shadow-sm w-full focus:outline-none focus:ring-blue-500 focus:border-blue-500 px-3 py-2"
                      />
                      <input
                        id="maxTimestamp"
                        type="date"
                        value={filters.maxTimestamp}
                        onChange={(e) =>
                          setFilters({ ...filters, maxTimestamp: e.target.value })
                        }
                        className="bg-white border border-gray-300 rounded-md shadow-sm w-full focus:outline-none focus:ring-blue-500 focus:border-blue-500 px-3 py-2"
                      />
                    </div>
                  </div>
                  {/* Listing Type Filter */}
                  <div className="flex flex-col">
                    <label
                      htmlFor="listingType"
                      className="text-gray-700 font-medium mb-1"
                    >
                      Listing Type
                    </label>
                    <select
                      id="listingType"
                      value={filters.listingType}
                      onChange={(e) =>
                        setFilters({ ...filters, listingType: e.target.value })
                      }
                      className="bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 px-3 py-2"
                    >
                      <option value="">All</option>
                      <option value="classified">Classified</option>
                      <option value="online_auction">Online Auction</option>
                      <option value="auction">Auction</option>
                      <option value="eol">EOL</option>
                      <option value="tenders">Tenders</option>
                    </select>
                  </div>
                  {/* Site Filter */}
                  <div className="flex flex-col">
                    <label
                      htmlFor="site"
                      className="text-gray-700 font-medium mb-1"
                    >
                      Site
                    </label>
                    <select
                      id="site"
                      value={filters.site}
                      onChange={(e) =>
                        setFilters({ ...filters, site: e.target.value })
                      }
                      className="bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 px-3 py-2"
                    >
                      <option value="">All</option>
                      <option value="facebook">Facebook</option>
                      <option value="trade_me">Trade Me</option>
                      <option value="turners">Turners</option>
                    </select>
                  </div>
                  {/* Hide No Price Toggle */}
                  <div className="flex items-center mt-4">
                    <input
                      type="checkbox"
                      checked={filters.hideNoPrice}
                      onChange={(e) =>
                        setFilters({ ...filters, hideNoPrice: e.target.checked })
                      }
                      className="form-checkbox h-5 w-5 text-blue-600"
                    />
                    <label className="ml-2 text-gray-700 font-medium">
                      Hide No Price
                    </label>
                  </div>
                  {/* Hide Inactive Toggle */}
                  <div className="flex items-center mt-4">
                    <input
                      type="checkbox"
                      checked={filters.hideInactive}
                      onChange={(e) =>
                        setFilters({ ...filters, hideInactive: e.target.checked })
                      }
                      className="form-checkbox h-5 w-5 text-blue-600"
                    />
                    <label className="ml-2 text-gray-700 font-medium">
                      Hide Inactive
                    </label>
                  </div>
                  {/* Only Recommended Toggle */}
                  <div className="flex items-center mt-4">
                    <input
                      type="checkbox"
                      checked={filters.onlyRecommended}
                      onChange={(e) =>
                        setFilters({
                          ...filters,
                          onlyRecommended: e.target.checked,
                        })
                      }
                      className="form-checkbox h-5 w-5 text-blue-600"
                    />
                    <label className="ml-2 text-gray-700 font-medium">
                      Only Recommended
                    </label>
                  </div>
                  {/* Hide Seen Listings Toggle */}
                  <div className="flex items-center mt-4">
                    <input
                      type="checkbox"
                      checked={hideSeen}
                      onChange={(e) => setHideSeen(e.target.checked)}
                      className="form-checkbox h-5 w-5 text-blue-600"
                    />
                    <label className="ml-2 text-gray-700 font-medium">
                      Hide Seen Listings
                    </label>
                  </div>
                  {/* Hide Weird Prices Toggle */}
                  <div className="flex items-center mt-4">
                    <input
                      type="checkbox"
                      checked={filters.hideWeirdPrices}
                      onChange={(e) =>
                        setFilters({
                          ...filters,
                          hideWeirdPrices: e.target.checked,
                        })
                      }
                      className="form-checkbox h-5 w-5 text-blue-600"
                    />
                    <label className="ml-2 text-gray-700 font-medium">
                      Hide Weird Prices
                    </label>
                  </div>
                  {/* Hide Weird Listings Toggle */}
                  <div className="flex items-center mt-4">
                    <input
                      type="checkbox"
                      checked={filters.hideWeirdListings}
                      onChange={(e) =>
                        setFilters({
                          ...filters,
                          hideWeirdListings: e.target.checked,
                        })
                      }
                      className="form-checkbox h-5 w-5 text-blue-600"
                    />
                    <label className="ml-2 text-gray-700 font-medium">
                      Hide Weird Listings
                    </label>
                  </div>
                  {/* Existing Filter Options */}
                  <div className="flex flex-col">
                    <label
                      htmlFor="filter"
                      className="text-gray-700 font-medium mb-1"
                    >
                      Filter
                    </label>
                    <select
                      id="filter"
                      value={filter}
                      onChange={(e) => setFilter(e.target.value)}
                      className="bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 px-3 py-2"
                    >
                      <option value="all">All</option>
                      <option value="found">Below Market Value</option>
                      <option value="failed">Above Market Value</option>
                      <option value="unevaluated">Unevaluated</option>
                    </select>
                  </div>
                  {/* Sort Options */}
                  <div className="flex flex-col">
                    <label
                      htmlFor="sort"
                      className="text-gray-700 font-medium mb-1"
                    >
                      Sort by
                    </label>
                    <select
                      id="sort"
                      value={`${sortConfig.key}-${sortConfig.direction}`}
                      onChange={(e) => {
                        const [key, direction] = e.target.value.split('-');
                        setSortConfig({ key, direction });
                      }}
                      className="bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 px-3 py-2"
                    >
                      <option value="timestamp-descending">
                        Timestamp (Newest First)
                      </option>
                      <option value="timestamp-ascending">
                        Timestamp (Oldest First)
                      </option>                  
                      <option value="price-ascending">
                        Price (Low to High)
                      </option>
                      <option value="price-descending">
                        Price (High to Low)
                      </option>
                      <option value="year-ascending">
                        Year (Oldest First)
                      </option>
                      <option value="year-descending">
                        Year (Newest First)
                      </option>
                      <option value="odometer-ascending">
                        Odometer (Low to High)
                      </option>
                      <option value="odometer-descending">
                        Odometer (High to Low)
                      </option>
                      <option value="make-ascending">Make (A to Z)</option>
                      <option value="make-descending">Make (Z to A)</option>
                      <option value="model-ascending">Model (A to Z)</option>
                      <option value="model-descending">Model (Z to A)</option>
                      <option value="location-ascending">
                        Location (A to Z)
                      </option>
                      <option value="location-descending">
                        Location (Z to A)
                      </option>
                    </select>
                  </div>
                  {/* Items per page */}
                  <div className="flex flex-col">
                    <label
                      htmlFor="itemsPerPage"
                      className="text-gray-700 font-medium mb-1"
                    >
                      Items per page
                    </label>
                    <select
                      id="itemsPerPage"
                      value={itemsPerPage}
                      onChange={(e) =>
                        setItemsPerPage(parseInt(e.target.value))
                      }
                      className="bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 px-3 py-2"
                    >
                      <option value={5}>5</option>
                      <option value={10}>10</option>
                      <option value={25}>25</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                    </select>
                  </div>
                </div>
              </div>

              {/* Vehicle List */}
              <div className="space-y-4">
                {view === 'card' ? (
                  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                    {paginatedVehicles.map((vehicle) => (
                      <SwipeableVehicle key={vehicle.id} vehicle={vehicle} />
                    ))}
                  </div>
                ) : (
                  <TableView />
                )}
              </div>

              {/* Pagination controls */}
              {totalPages > 1 && (
                <div className="flex justify-center space-x-2">
                  <button
                    onClick={() =>
                      setCurrentPage((prev) => Math.max(prev - 1, 1))
                    }
                    disabled={currentPage === 1}
                    className="px-4 py-2 bg-white border border-gray-300 rounded-md hover:bg-gray-100 disabled:opacity-50"
                  >
                    Previous
                  </button>
                  <span className="px-4 py-2 text-gray-700">
                    Page {currentPage} of {totalPages}
                  </span>
                  <button
                    onClick={() =>
                      setCurrentPage((prev) => Math.min(prev + 1, totalPages))
                    }
                    disabled={currentPage === totalPages}
                    className="px-4 py-2 bg-white border border-gray-300 rounded-md hover:bg-gray-100 disabled:opacity-50"
                  >
                    Next
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default VehicleList;