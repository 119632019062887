import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { ref, update } from 'firebase/database';
import Login from './pages/auth/Login';
import VehicleList from './pages/VehicleList';
// import FilterList from './pages/FilterList';
// import FilterForm from './pages/FilterForm';
import Sidebar from './components/Sidebar';
import Loading from './components/Loading';
import Settings from './pages/Settings';
import ChangeEmail from './pages/auth/ChangeEmail';
import ChangePassword from './pages/auth/ChangePassword';
import MarketAndValuationAnalysis from './pages/MarketAndValuationAnalysis';
import { auth, database, messaging, getToken } from './firebase';
import VehicleFilter from './pages/VehicleFilter';
import ClassifiedPage from './pages/Classified';
import AuctionsPage from './pages/Auction';

const App = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setUser(user);
      setLoading(false); // Set loading to false after checking auth state
      if (user) {
        await requestNotificationPermission(user.uid); // Request notification permission and get FCM token
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  if (loading) {
    return <Loading />; // Show loading spinner while checking auth state
  }

  return (
    <Router>
      <AppContent user={user} />
    </Router>
  );
};

const AppContent = ({ user }) => {
  const location = useLocation();
  const isLoginPage = location.pathname === '/login';

  return (
    <div>
      {!isLoginPage && <Sidebar user={user} />}
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/vehicles" element={user ? <VehicleList /> : <Navigate to="/login" />} />
        <Route path="/filter" element={user ? <VehicleFilter /> : <Navigate to="/login" />} />
        <Route path="/" element={user ? <ClassifiedPage /> : <Navigate to="/login" />} />
        <Route path="/auctions" element={user ? <AuctionsPage /> : <Navigate to="/login" />} />
        {/* <Route path="/filters" element={user ? <FilterList /> : <Navigate to="/login" />} /> */}
        {/* <Route path="/edit-filter/:id" element={user ? <FilterForm mode="edit" /> : <Navigate to="/login" />} />
        <Route path="/add-filter" element={user ? <FilterForm mode="add" /> : <Navigate to="/login" />} /> */}
        <Route path="/analysis" element={user ? <MarketAndValuationAnalysis /> : <Navigate to="/login" />} />
        <Route path="/settings" element={user ? <Settings /> : <Navigate to="/login" />} />
        <Route path="/change-email" element={user ? <ChangeEmail /> : <Navigate to="/login" />} />
        <Route path="/change-password" element={user ? <ChangePassword /> : <Navigate to="/login" />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
};

const NotFound = () => (
  <div className="container mx-auto p-4">
    <h1 className="text-2xl">404 - Page Not Found</h1>
    <p>The page you are looking for does not exist.</p>
  </div>
);

const requestNotificationPermission = async (uid) => {
  try {
    const permission = await Notification.requestPermission();
    if (permission === 'granted') {
      const fcmToken = await getToken(messaging, { vapidKey: 'BNCKcFl397-_j9mkzPnfg9cSDubiCHwfUHQ8ihkJzE_wMkWrXOyyRZguMo_B3oe7qBSgqsBEImvXpWPydiyrJLQ' });
      if (fcmToken) {
        const platform = getPlatform();
        const tokenRef = ref(database, `users/${uid}/fcm_tokens/${fcmToken}`);
        await update(tokenRef, { platform });
      }
    }
  } catch (error) {
    console.error('Error getting FCM token:', error);
  }
};

const getPlatform = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'apple';
  }

  if (/android/i.test(userAgent)) {
    return 'android';
  }

  return 'web';
};

export default App;
