import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Loading from './../components/Loading';
import { getDatabase, ref, get } from 'firebase/database';
import { getAuth } from 'firebase/auth';
import SwipeableCard from './../components/SwipeableCard';

const AuctionsPage = () => {
  const [vehicles, setVehicles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sortOption, setSortOption] = useState('latestListings');
  const [siteFilter, setSiteFilter] = useState('');
  const [locationFilter, setLocationFilter] = useState('');
  const [priceDifference, setPriceDifference] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [seenListings, setSeenListings] = useState([]);
  const [manufacturersFilter, setManufacturersFilter] = useState([]);
  const [userRegion, setUserRegion] = useState('');
  const [locationOptions, setLocationOptions] = useState(['Wellington', 'Christchurch']); // Predefined options

  const db = getDatabase();
  const user = getAuth().currentUser;

  // Helper function to capitalize the first letter
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  useEffect(() => {
    // Ensure user is logged in
    if (!user) {
      console.error('User not authenticated');
      setLoading(false);
      return;
    }

    const fetchVehicles = async () => {
      try {
        // References to user-specific data in Firebase
        const apiKeyRef = ref(db, `users/${user.uid}/settings/db_api_key`);
        const priceDifferenceRef = ref(db, `users/${user.uid}/filter/price_difference`);
        const filterRef = ref(db, `users/${user.uid}/filter/`);
        const userLocationRef = ref(db, `users/${user.uid}/settings/user_region`);

        // Fetch all necessary data concurrently
        const [apiKeySnapshot, priceDiffSnapshot, filterSnapshot, userRegionSnapshot] = await Promise.all([
          get(apiKeyRef),
          get(priceDifferenceRef),
          get(filterRef),
          get(userLocationRef),
        ]);

        const apiKey = apiKeySnapshot.val();
        const priceDiff = priceDiffSnapshot.val() || 0;
        setPriceDifference(priceDiff);

        // Get user region and capitalize the first letter
        const fetchedUserRegion = userRegionSnapshot.val();
        if (fetchedUserRegion) {
          const formattedRegion = capitalizeFirstLetter(fetchedUserRegion);
          setLocationFilter(formattedRegion);
          setUserRegion(formattedRegion);

          // If userRegion is not in the predefined options, add it
          if (!locationOptions.includes(formattedRegion)) {
            setLocationOptions((prevOptions) => [...prevOptions, formattedRegion]);
          }
        }

        // Fetch filter settings
        const filterData = filterSnapshot.val();
        let selectedManufacturers = [];
        if (filterData && filterData.manufacturers) {
          selectedManufacturers = Object.keys(filterData.manufacturers).filter(
            (key) => filterData.manufacturers[key]
          );
          setManufacturersFilter(selectedManufacturers);
        }

        // Fetch all vehicles
        const response = await axios.get(
          'https://api.harryludemann.com/api/v1/secure/unseen',
          { headers: { 'X-API-KEY': apiKey } }
        );
        const allVehicles = response.data;

        // Set vehicles state
        setVehicles(allVehicles);
      } catch (error) {
        console.error('Error fetching auctions:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchVehicles();
  }, [db, user, locationOptions]);

  const markAsSeen = async (id) => {
    try {
      const apiKeyRef = ref(db, `users/${user.uid}/settings/db_api_key`);
      const apiKey = await get(apiKeyRef).then((snapshot) => snapshot.val());
      await axios.post(
        'https://api.harryludemann.com/api/v1/secure/seen/',
        {
          listing_id: id,
        },
        { headers: { 'X-API-KEY': apiKey } }
      );
      // Update the seenListings array
      setSeenListings((prevSeenListings) => [...prevSeenListings, id]);

      // Remove the vehicle from the vehicles state
      setVehicles((prevVehicles) => prevVehicles.filter(vehicle => vehicle.id !== id));
    } catch (error) {
      console.error('Error marking vehicle as seen:', error);
    }
  };

  const filteredVehicles = () => {
    // Normalize the manufacturers filter to lowercase and trim whitespace
    const normalizedManufacturers = manufacturersFilter.map(make =>
      make.toLowerCase().trim()
    );

    return vehicles.filter(vehicle => {
      // Check if the listing type is 'classified'
      if (vehicle.listing_type !== 'classified') {
        return false;
      }

      // If manufacturersFilter is not empty, perform a generous, case-insensitive match
      if (normalizedManufacturers.length > 0) {
        const vehicleMake = vehicle.make ? vehicle.make.toLowerCase().trim() : '';

        // Check if any filter make is included in the vehicle make
        const makeMatches = normalizedManufacturers.some(filterMake =>
          vehicleMake.includes(filterMake)
        );

        if (!makeMatches) {
          return false;
        }
      }

      // If siteFilter is applied, ensure the vehicle's site matches
      if (siteFilter && vehicle.site !== siteFilter) {
        return false;
      }

      // If locationFilter is applied, perform a case-insensitive search within the location
      if (locationFilter) {
        const vehicleLocation = vehicle.location
          ? vehicle.location.toLowerCase()
          : '';
        const normalizedLocationFilter = locationFilter.toLowerCase();

        if (!vehicleLocation.includes(normalizedLocationFilter)) {
          return false;
        }
      }

      // If all conditions pass, include the vehicle in the filtered list
      return true;
    });
  };

  const sortVehicles = (filteredVehicles) => {
    return [...filteredVehicles].sort((a, b) => {
      switch (sortOption) {
        case 'belowMarketValue':
          return (b.medium_estimate - (b.price || 0)) - (a.medium_estimate - (a.price || 0));
  
        case 'latestListings':
          return new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime();
  
        case 'closingSoon':
          return new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime();
  
        case 'priceHighToLow':
          return (b.price || 0) - (a.price || 0);
  
        case 'priceLowToHigh':
          return (a.price || 0) - (b.price || 0);
  
        default:
          return 0;
      }
    });
  };

  const getPriceColor = (priceDiff, userPriceDifference) => {
    if (priceDiff >= userPriceDifference * 2) {
      return 'text-green-600';
    } else if (priceDiff >= userPriceDifference) {
      return 'text-green-500';
    } else if (priceDiff >= 0) {
      return 'text-orange-500';
    } else {
      return 'text-red-500';
    }
  };

  const vehicleProperties = [
    { label: 'Make', key: 'make' },
    { label: 'Model', key: 'model' },
    { label: 'Year', key: 'year' },
    { label: 'Odometer', key: 'odometer', format: (value) => `${value.toLocaleString()} km` },
    { label: 'WOF', key: 'wof' },
    { label: 'Registration', key: 'registration' },
    { label: 'Location', key: 'location' },
    { label: 'Closing Time', key: 'closingTime', format: (value) => new Date(value).toLocaleString() },
    { label: 'Listed On', key: 'timestamp', format: (value) => new Date(value).toLocaleDateString() },
    { label: 'Site', key: 'site' },
    // Add more fields as necessary
  ];

  // Reset current page when filters change
  useEffect(() => {
    setCurrentPage(1);
  }, [sortOption, siteFilter, locationFilter, itemsPerPage]);

  const sortedVehicles = sortVehicles(filteredVehicles());

  // Pagination logic
  const indexOfLastVehicle = currentPage * itemsPerPage;
  const indexOfFirstVehicle = indexOfLastVehicle - itemsPerPage;
  const currentVehicles = sortedVehicles.slice(indexOfFirstVehicle, indexOfLastVehicle);
  const totalPages = sortedVehicles.length > 0 ? Math.ceil(sortedVehicles.length / itemsPerPage) : 1;

  return (
    <div className="container mx-auto p-4 min-h-screen pt-10">

      {loading ? (
        <Loading />
      ) : (
        <div>
          <h1 className="text-3xl font-extrabold mb-6">Auction Listings</h1>
          <div className="flex flex-col md:flex-row md:flex-wrap gap-4 mb-6">
            <div className="flex flex-col w-full md:w-auto">
              <label className="font-medium mb-1 md:mr-2 md:mb-0">Sort By:</label>
              <select
                value={sortOption}
                onChange={(e) => setSortOption(e.target.value)}
                className="border p-2 rounded w-full md:w-auto"
              >
                <option value="belowMarketValue">Below Market Value</option>
                <option value="latestListings">Latest Listings</option>
                <option value="closingSoon">Closing Soon</option>
                <option value="priceHighToLow">Price High to Low</option>
                <option value="priceLowToHigh">Price Low to High</option>
              </select>
            </div>

            <div className="flex flex-col w-full md:w-auto">
              <label className="font-medium mb-1 md:mr-2 md:mb-0">Filter By Site:</label>
              <select
                value={siteFilter}
                onChange={(e) => setSiteFilter(e.target.value)}
                className="border p-2 rounded w-full md:w-auto"
              >
                <option value="">All</option>
                <option value="turners">Turners Only</option>
                <option value="trade_me">Trade Me Only</option>
                <option value="facebook">Facebook Only</option>
              </select>
            </div>

            <div className="flex flex-col w-full md:w-auto">
              <label className="font-medium mb-1 md:mr-2 md:mb-0">Filter By Location:</label>
              <select
                value={locationFilter}
                onChange={(e) => setLocationFilter(e.target.value)}
                className="border p-2 rounded w-full md:w-auto"
              >
                <option value="">All</option>
                {locationOptions.map((location) => (
                  <option key={location} value={location}>
                    {location}
                  </option>
                ))}
              </select>
            </div>

            <div className="flex flex-col w-full md:w-auto">
              <label className="font-medium mb-1 md:mr-2 md:mb-0">Items per page:</label>
              <select
                value={itemsPerPage}
                onChange={(e) => setItemsPerPage(Number(e.target.value))}
                className="border p-2 rounded w-full md:w-auto"
              >
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
            </div>
          </div>

          <ul className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {currentVehicles.map((vehicle) => (
              <SwipeableCard
                key={vehicle.id}
                vehicle={vehicle}
                onMarkAsSeen={markAsSeen}
                vehicleProperties={vehicleProperties}
                getPriceColor={getPriceColor}
                priceDifference={priceDifference}
              />
            ))}
          </ul>
          
          <div className="flex justify-between items-center mt-4">
            {totalPages > 1 && (
              <>
                <button
                  onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                  disabled={currentPage === 1}
                  className="px-4 py-2 bg-gray-200 rounded disabled:opacity-50"
                >
                  Previous
                </button>
                <span>
                  Page {currentPage} of {totalPages}
                </span>
                <button
                  onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                  disabled={currentPage === totalPages}
                  className="px-4 py-2 bg-gray-200 rounded disabled:opacity-50"
                >
                  Next
                </button>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default AuctionsPage;
