import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  TagIcon,
  CurrencyDollarIcon,
  FunnelIcon,
  TruckIcon,
  CogIcon,
  Bars3Icon,
  XMarkIcon,
} from '@heroicons/react/24/solid';
import './../style/Sidebar.css';

export const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const sidebarRef = useRef(null);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const isActive = (path) => {
    return location.pathname === path;
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if the sidebar is open and if the click happened outside of the sidebar
      if (isOpen && sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setIsOpen(false); // Close the sidebar
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  return (
    <>
      <header className="header">
        <div className="flex justify-between items-center h-14 md:h-16 px-4 w-full bg-transparent">
          <div className="flex-grow" />
          <div className="hamburger" onClick={toggleMenu}>
            <div className="circle">
              {isOpen ? (
                <XMarkIcon className="icon" />
              ) : (
                <Bars3Icon className="icon" />
              )}
            </div>
          </div>
        </div>
      </header>

      <nav
        ref={sidebarRef}
        id="drawer"
        className={`fixed right-0 top-0 w-60 md:w-80 bg-white h-full shadow-lg overflow-y-auto z-50 transition-transform duration-300 ease-in-out ${
          isOpen ? 'translate-x-0' : 'translate-x-full'
        }`}
      >
        <ul className="mt-16">
          {[
            { to: '/', icon: <TagIcon className="h-6 w-6" />, text: 'CLASSIFIEDS' },
            { to: '/auctions', icon: <CurrencyDollarIcon className="h-6 w-6" />, text: 'AUCTIONS' },
            { to: '/filter', icon: <FunnelIcon className="h-6 w-6" />, text: 'FILTERS' },
            { to: '/vehicles', icon: <TruckIcon className="h-6 w-6" />, text: 'VEHICLES' },
            { to: '/settings', icon: <CogIcon className="h-6 w-6" />, text: 'SETTINGS' },
          ].map((item, index) => (
            <li key={index}>
              <Link
                to={item.to}
                onClick={toggleMenu}
                className={`flex items-center p-4 border-b border-gray-200 ${
                  isActive(item.to)
                    ? 'bg-[#146C94] text-white'
                    : 'bg-transparent text-[#146C94]'
                } transition-colors duration-300 hover:bg-[#146C94] hover:text-white`}
              >
                <span className="mr-4">{item.icon}</span>
                <span className="text-center">{item.text}</span>
              </Link>
            </li>
          ))}
        </ul>
      </nav>
    </>
  );
};

export default Sidebar;
